import { memo, ReactNode, useState } from "react";
import { ButtomHamburguer } from "../../Buttons";
import Logo from "assets/logo/logo-yesup.png";

interface INavBar {
  children: ReactNode;
}

const NavBar: React.FC<INavBar> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header-area fixed-top">
      <nav className="main-nav fixed-top">
        <a href="/" className="logo">
          <img src={Logo} alt="team meeting" width={80} height={50} />
        </a>
        <ul className={`nav ${isOpen ? "nav-ul" : ""}`}>{children}</ul>
      </nav>
      <ButtomHamburguer onClick={() => setIsOpen((state) => !state)} />
    </div>
  );
};

export default memo(NavBar);
