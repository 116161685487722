import { ReactNode } from "react";

interface IAppBar {
  text: string;
  index: number;
  indexInViewport: number;
  children?: ReactNode;
}
const Item = ({ text, index, indexInViewport, children }: IAppBar) => {
  return (
    <li
      key={index}
      className={indexInViewport === index ? "active" : ""}
      data-cy={`nav-item`}
    >
      {children ? (
        children
      ) : (
        <a
          href={`#section-${index}`}
          style={{
            color: indexInViewport === index ? "#f00" : "#222",
          }}
          id={`nav-item-${index}`}
        >
          {text}
        </a>
      )}
    </li>
  );
};
export default Item;
