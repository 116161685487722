import { useState } from "react";
import { ContactDecoration } from "../../assets";
import { Fade } from "react-awesome-reveal";
import emailjs from '@emailjs/browser';

const Contact = ({
  index,
  sectionRefs,
  indexInViewport,
}: {
  index: number;
  indexInViewport: number;
  sectionRefs: React.RefObject<HTMLDivElement>[];
}) => {

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [empresa, setEmpresa] = useState('')
  const [celular, setCelular] = useState('')
  const [mensagem, setMensagem] = useState('')


  function sendmail(e: { preventDefault: () => void; }) {
    e.preventDefault();
    emailjs.init({
      publicKey: 'vW6nG1rQlW0pZ3wr4'});

    const templateParams = {
      from_name: nome,
      message: mensagem,
      email: email,
      empresa: empresa,
      celular: celular
    }

    emailjs.send("service_0ahd5tg","template_s9upapp", templateParams)
    .then((response) => {
      alert("E-mail enviado com sucesso");
    },(erro) => {
      alert(erro)
    })
  }
  return (
    <div
      ref={sectionRefs[index]}
      className={`${indexInViewport === index ? "active" : ""}`}
      id={`section-${index}`}
      data-cy={`section-item`}
      key={index}
      style={{ marginTop: 50 }}
    >
      <div id="contact" className="contact-us section">
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-6 align-self-centerwow`}
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <Fade direction="left" delay={800} triggerOnce>
                <div className="section-heading">
                  <h2>Entre em contato com a gente.</h2>
                  <p>
                    Preencha o formulário ao lado que em breve entraremos em
                    contato.
                  </p>
                </div>
              </Fade>
            </div>
            <div
              className={`col-lg-6 wow`}
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <Fade direction="right" delay={400} triggerOnce>
                <form id="contact" onSubmit={sendmail}>
                  <div className="row">
                    <div className="col-lg-6">
                      <fieldset>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nome"
                          onChange={(e) => setNome(e.target.value)}
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <input
                          type="text"
                          name="empresa"
                          id="empresa"
                          placeholder="Empresa"
                          required
                          onChange={(e) => setEmpresa(e.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-mail"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <input
                          type="text"
                          name="celular"
                          id="celular"
                          placeholder="Celular"
                          required
                          onChange={(e) => setCelular(e.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          placeholder="Mensagem"
                          required
                          onChange={(e) => setMensagem(e.target.value)}
                        ></textarea>
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="main-button "
                        >
                          Enviar
                        </button>
                      </fieldset>
                    </div>
                  </div>
                  <div className="contact-dec">
                    <img src={ContactDecoration} alt="" />
                  </div>
                </form>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
