import { AboutLeft, IconFour, IconOne, IconTree, IconTwo } from "../../assets";

import { Fade } from "react-awesome-reveal";

const Service = ({
  index,
  sectionRefs,
  indexInViewport,
}: {
  index: number;
  indexInViewport: number;
  sectionRefs: React.RefObject<HTMLDivElement>[];
}) => {
  return (
    <div
      ref={sectionRefs[index]}
      className={`${indexInViewport === index ? "active" : ""}`}
      id={`section-${index}`}
      data-cy={`section-item`}
      key={index}
      style={{ paddingTop: 120 }}
    >
      <div id="services" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Fade delay={200} triggerOnce>
                <div
                  className={`left-image wow fadeInLeft`}
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <img src={AboutLeft} alt="team meeting" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="services">
                <div className="row">
                  <div className="col-lg-6">
                    <Fade delay={500} triggerOnce>
                      <div
                        className={`item wow fadeIn`}
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                      >
                        <div className="icon">
                          {" "}
                          <img src={IconOne} alt="team meeting" />
                        </div>
                        <div className="right-text">
                          <h4>Projetos WEB e Mobile</h4>
                          <p>Desenvolvimento de sistemas WEB e Mobile</p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6">
                    <Fade delay={700} triggerOnce>
                      <div
                        className={`item wow fadeIn`}
                        data-wow-duration="1s"
                        data-wow-delay="0.7s"
                      >
                        <div className="icon">
                          <img src={IconTwo} alt="team meeting" />
                        </div>
                        <div className="right-text">
                          <h4>Outsorcing</h4>
                          <p>
                            Alocação de profissionais de qualidade conforme sua
                            necessidade
                          </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6">
                    <Fade delay={900} triggerOnce>
                      <div
                        className={`item wow fadeIn`}
                        data-wow-duration="1s"
                        data-wow-delay="0.9s"
                      >
                        <div className="icon">
                          <img src={IconTree} alt="team meeting" />
                        </div>
                        <div className="right-text">
                          <h4>Hospedagem de sites</h4>
                          <p>
                            Hospedagem de sites e portais com qualidade e
                            segurança
                          </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6" style={{ zIndex: 1 }}>
                    <Fade delay={1100} triggerOnce>
                      <div
                        className={`item wow fadeIn `}
                        data-wow-duration="1s"
                        data-wow-delay="1.1s"
                      >
                        <div className="icon">
                          <img src={IconFour} alt="team meeting" />
                        </div>
                        <div className="right-text">
                          <h4>Gestão de projetos Ágil</h4>
                          <p>
                            Gestão e acompanhamento de projetos utilizando
                            metodologia ágil
                          </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service;
