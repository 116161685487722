import { Portfolio } from "../../assets";
import { Bounce } from "react-awesome-reveal";

const WebsiteHosting = ({
  index,
  sectionRefs,
  indexInViewport,
}: {
  index: number;
  indexInViewport: number;
  sectionRefs: React.RefObject<HTMLDivElement>[];
}) => {
  return (
    <div
      ref={sectionRefs[index]}
      className={`${indexInViewport === index ? "active" : ""}`}
      id={`section-${index}`}
      data-cy={`section-item`}
      key={index}
    >
      <div id="portfolio" className="our-portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <Bounce delay={100} triggerOnce>
                <div className="section-heading">
                  <h2>Hospedagem de sites</h2>
                </div>
              </Bounce>
            </div>
          </div>
          <Bounce delay={500} reverse={false} direction="up" triggerOnce>
            <div className={`row`}>
              <div className="col-lg-4 col-sm-6">
                <a href="/">
                  <div
                    className="item wow bounceInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <div className="hidden-content">
                      <h4>BRONZE</h4>
                      <p>Para você que está iniciando seu projeto.</p>
                    </div>
                    <div className="showed-content">
                      <p>2 Site</p>
                      <p>500 MB de memória</p>
                      <p>20 GB de armazenamento</p>
                      <p>2 banco de dados (Mysql e SQL Server)</p>
                      <p>3 E-mail seguro.</p>
                      <p>Instalação do Wordpress em 1 clique grátis.</p>
                      <h3><span id="blue">R$ 35,00</span></h3>
                      <img src={Portfolio} alt="team meeting" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a href="/">
                  <div
                    className="item wow bounceInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <div className="hidden-content">
                      <h4>PRATA</h4>
                      <p>Para você que precisa de um pouco mais de recursos.</p>
                    </div>
                    <div className="showed-content">
                      <p>3 Site</p>
                      <p>700 MB de memória</p>
                      <p>40 GB de armazenamento</p>
                      <p>5 banco de dados (Mysql e SQL Server)</p>
                      <p>10 E-mail seguro.</p>
                      <p>Instalação do Wordpress em 1 clique grátis.</p>
                      <h3><span id="blue">R$ 50,00</span></h3>
                      <img src={Portfolio} alt="team meeting" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a href="/">
                  <div
                    className="item wow bounceInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <div className="hidden-content">
                      <h4>OURO</h4>
                      <p>Para você que busca tranquilidade e liberdade.</p>
                    </div>
                    <div className="showed-content">
                      <p>5 Site</p>
                      <p>1 GB de memória</p>
                      <p>60 GB de armazenamento</p>
                      <p>10 banco de dados (Mysql e SQL Server)</p>
                      <p>20 E-mail seguro.</p>
                      <p>Instalação do Wordpress em 1 clique grátis.</p>
                      <h3><span id="blue">R$ 80,00</span></h3>
                      <img src={Portfolio} alt="team meeting" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Bounce>
        </div>
      </div>
    </div>
  );
};
export default WebsiteHosting;
