const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.25s"
          >
            <p>© Copyright 2022 YesUP. Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
