import "./index.css";

interface IButtom {
  onClick?: () => void;
}

const Buttom: React.FC<IButtom> = ({ onClick }) => {
  return (
    <div className="div-hamburguer">
      <input id="menu-hamburguer" type="checkbox" onClick={onClick} />

      <label htmlFor="menu-hamburguer">
        <div className="menu">
          <span className="hamburguer"></span>
        </div>
      </label>
    </div>
  );
};
export default Buttom;
