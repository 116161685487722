import { Scrollspy } from "@makotot/ghostui";
import { useRef } from "react";
import "./App.css";
import About from "components/About";
import AppBar from "components/AppBar";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Home from "components/Home";
import Service from "components/Service";
import WebsiteHosting from "components/WebsiteHosting";

function App() {
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  return (
    <>
      <Scrollspy sectionRefs={sectionRefs} offset={-200}>
        {({ currentElementIndexInViewport }) => (
          <>
            <AppBar
              pages={[
                "Home",
                "Sobre",
                "Serviços",
                "Hospedagem de sites",
                "Contato",
              ]}
              indexInViewport={currentElementIndexInViewport}
            />
            <Home
              index={0}
              indexInViewport={currentElementIndexInViewport}
              sectionRefs={sectionRefs}
            />
            <About
              index={1}
              indexInViewport={currentElementIndexInViewport}
              sectionRefs={sectionRefs}
            />
            <Service
              index={2}
              indexInViewport={currentElementIndexInViewport}
              sectionRefs={sectionRefs}
            />
            <WebsiteHosting
              index={3}
              indexInViewport={currentElementIndexInViewport}
              sectionRefs={sectionRefs}
            />
            <Contact
              index={4}
              indexInViewport={currentElementIndexInViewport}
              sectionRefs={sectionRefs}
            />
          </>
        )}
      </Scrollspy>
      <Footer />
    </>
  );
}

export default App;
