import { ServicesLeft } from "../../assets";
import { Fade } from "react-awesome-reveal";

const About = ({
  index,
  sectionRefs,
  indexInViewport,
}: {
  index: number;
  indexInViewport: number;
  sectionRefs: React.RefObject<HTMLDivElement>[];
}) => {
  //var testData = document.getElementById("section-0");

  // const posicoes = testData?.getBoundingClientRect();
  // const inicio = posicoes?.top || 0;
  // const fim = posicoes?.bottom || 0;

  // console.log("fim ", fim > 780 / 2);

  return (
    <div
      ref={sectionRefs[index]}
      className={`${indexInViewport === index ? "active" : ""}`}
      id={`section-${index}`}
      data-cy={`section-item`}
      key={index}
    >
      <div id="about" className="our-services section">
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-6 align-self-center wow`}
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <Fade direction="left" delay={400} triggerOnce>
                <div className="left-image">
                  <img src={ServicesLeft} alt="team meeting" />
                </div>
              </Fade>
            </div>
            <div
              className={`col-lg-6 wow fadeInRight`}
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <Fade direction="right" delay={400} triggerOnce>
                <div className="section-heading">
                  <h2>
                    Experiência, Confiabilidade e Simplicidade. <em></em>{" "}
                    <span></span>{" "}
                  </h2>
                  <p>
                    Em uma sociedade globalizada, com os avanços tecnológicos e
                    as inovações oriundas da transformação digital, a
                    assertividade nos custos, na qualidade e no prazo de entrega
                    de novos produtos ou serviços é essencial para que os
                    objetivos estratégicos da empresa tenham sucesso e crie as
                    condições favoráveis para o crescimento no mercado de
                    atuação da organização.
                  </p>
                  <p>
                    A YesUP, através dos mais de 15 anos de experiência de seus
                    fundadores em projetos de alta relevância em empresas
                    multinacionais, tem como objetivo criar uma parceira
                    transparente e comprometida com os resultados de seus
                    clientes. Utilizamos de forma simples e objetiva as
                    metodologias ágeis, focados na utilização das inovações
                    tecnologias, criando uma sinergia vencedora entre os
                    stakeholders, time de desenvolvimento e diretoria de seus
                    clientes.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
