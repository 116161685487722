import { memo, useMemo } from "react";
import Item from "./Item";
import NavBar from "./NavBar";

interface IAppBar {
  pages: any[];
  indexInViewport: number;
}

const AppBar = ({ pages, indexInViewport }: IAppBar) => {
  const item = useMemo(() => {
    return (
      <>
        {pages.map((item, i) => {
          if (i === pages.length - 1) {
            return (
              <Item
                text={item}
                index={i}
                indexInViewport={indexInViewport}
                key={item}
              >
                <div className="main-red-button">
                  <a href={`#section-${i}`}> {item} </a>
                </div>
              </Item>
            );
          }
          return (
            <Item
              text={item}
              index={i}
              indexInViewport={indexInViewport}
              key={item}
            />
          );
        })}
      </>
    );
  }, [indexInViewport, pages]);

  return <NavBar>{item}</NavBar>;
};
export default memo(AppBar);
