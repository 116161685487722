import { BannerRight } from "../../assets";
import "./index.css";
import { Fade } from "react-awesome-reveal";

const Home = ({
  index,
  sectionRefs,
  indexInViewport,
}: {
  index: number;
  indexInViewport: number;
  sectionRefs: React.RefObject<HTMLDivElement>[];
}) => {
  return (
    <div
      ref={sectionRefs[index]}
      className={`main-banner ${indexInViewport === index ? "active" : ""}`}
      id={`section-${index}`}
      data-cy={`section-item`}
      key={index}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <Fade direction="left" delay={800} triggerOnce>
              <div
                className="left-content header-text wow"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <h6>Seja bem-vindo a YesUP</h6>
                <h2>
                  Nós ajudamos <span id="blue">você</span> a conquistar o{" "}
                  <span id="red">sucesso</span> nos seus{" "}
                  <span id="blue">projetos</span>.
                </h2>
              </div>
            </Fade>
          </div>
          <div className="col-lg-6">
            <Fade direction="right" delay={400} triggerOnce>
              <img src={BannerRight} alt="team meeting" />
            </Fade>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
export default Home;
